import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import './ChatInterface.css';

const ChatInterface = () => {
    const [messages, setMessages] = useState([]);
    const [options, setOptions] = useState([]);

    const personalInfo = useMemo(() => ({
        name: "Dhayakumar Rajan Prakash",
        intro: "Hello! I'm Dhayakumar, a biomedical engineer with expertise in R&D leadership, product development, and project management. How can I assist you today?",
        contact: {
            email: "mailto:hey@dhayakumar.com",
            linkedin: "https://www.linkedin.com/in/dhayakumar/"
        },
        currentRole: {
            title: "Head of Innovations and Process Control",
            company: "Brinter AM Technologies Oy",
            location: "Turku, Finland",
            duration: "April 2024 - Present",
            responsibilities: [
                "Lead R&D initiatives in Advanced manufacturing research",
                "Spearhead new technology innovation projects",
                "Foster industrial collaborations",
                "Secure EU project funding"
            ]
        },
        experience: [
            {
                title: "Bionic Engineer and Project Manager",
                company: "Brinter Oy",
                location: "Turku, Finland",
                duration: "February 2021 - March 2024",
                achievements: [
                    "Developed two innovative tool heads for bioprinting",
                    "Established a new pharmaceutical R&D division",
                    "Filed 4 patents as lead inventor, with 1 granted",
                    "Secured several million euros in funding"
                ]
            },
            {
                title: "Senior Researcher / Chief Innovation Officer (CIO)",
                company: "CHINFIN Innovation and Incubator Oy",
                location: "Turku, Finland",
                duration: "August 2018 - September 2020",
                achievements: [
                    "Filed and secured 5-6 patents in microfluidic and organ-on-chip technologies",
                    "Established valuable industrial collaborations",
                    "Delivered functional organ-on-chip platforms and lab-on-chip technologies"
                ]
            }
        ],
        education: [
            {
                degree: "Ph.D. in Pharmaceutical Science",
                institution: "Åbo Akademi University",
                location: "Turku, Finland",
                duration: "Aug 2018 - Current"
            },
            {
                degree: "Master's in Medical Nano Technology",
                institution: "SASTRA University",
                location: "Thanjavur, India",
                duration: "2013 - 2018"
            },
            {
                degree: "Bachelor's in Bio-Engineering",
                institution: "SASTRA University",
                location: "Thanjavur, India",
                duration: "2013 - 2018"
            }
        ],
        skills: [
            "Project Management: Trello, Asana, Agile methodologies",
            "Programming: C, C++, Arduino, Python, MATLAB",
            "Software: SolidWorks, Shaper3D, Fusion 360, Inventor, ImageJ, PyMOL, AutoCAD",
            "Office: PowerPoint, Word, Excel, Teams, OneNote",
            "Adobe: Photoshop, Illustrator, After Effects",
            "Tissue Engineering: Electrospinning, hydrogel optimization, scaffold fabrication",
            "Nanomaterials: Synthesis and characterization, TEM, SEM, liposomes",
            "Pharmaceutical Characterization: DSC, FTIR, Zeta potential, UV spectrometry",
            "Immunology: ELISA, electrophoresis, antigen-antibody reactions",
            "Molecular Biology: PCR, primer design, DNA/RNA isolation",
            "Biochemistry: Chromatographic techniques, cholesterol, amino acid quantification",
            "Microbiology: Bacterial and fungal culturing and staining",
            "Electronics: Signal processing, analog circuits, biomedical devices",
            "Microscopy: Light, phase contrast, confocal microscopy",
            "Automation: Microfluidic automation, sensor integration",
            "DIY Electronics and Prototyping: 3D printer design, sensor development"
        ],
        patentsPublications: {
            patents: [
                "<a href='https://patents.google.com/?q=(dhayakumar)&oq=dhayakumar'>Google Patents (Dhayakumar)</a>",
                "<a href='https://patents.google.com/?inventor=%E8%BE%BE%E5%93%88%E5%BA%93%E9%A9%AC%E5%B0%94%C2%B7%E6%8B%89%E8%A9%B9%C2%B7%E6%99%AE%E6%8B%89%E5%8D%A1%E4%BB%80'>Google Patents (Chinese name: 达哈库马尔)</a>"
            ],
            publications: "<a href='https://www.researchgate.net/profile/Dhayakumar-Rajan-Prakash'>ResearchGate</a>"
        },
        languages: ["English (Fluent)", "Finnish (basic)", "Tamil (Native)"]
    }), []);

    const addMessage = useCallback((text, sender) => {
        setMessages(prevMessages => [...prevMessages, { text, sender }]);
    }, []);

    const showOptions = useCallback((optionList) => {
        setOptions(optionList);
    }, []);

    useEffect(() => {
        addMessage(personalInfo.intro, 'bot');
        showOptions(["Tell me about yourself", "What's your current role?", "Tell me about your past experience", "What's your educational background?", "What are your skills?", "Any patents or publications?"]);
    }, [addMessage, showOptions, personalInfo.intro]);
    const handleOptionClick = useCallback((option) => {
        addMessage(option, 'user');
        setOptions([]);

        let response;
        let nextOptions;
        let references;

        switch (option) {
            case "Tell me about yourself":
                response = `I'm ${personalInfo.name}, currently working as ${personalInfo.currentRole.title} at ${personalInfo.currentRole.company}. I have extensive experience in R&D leadership, product development, and project management in the biomedical field, pharmaceutical technology, medical nanotechnology.`;
                references = "Keywords: <a href='https://en.wikipedia.org/wiki/Biomedical_engineering'>Biomedical Engineering</a>, <a href='https://en.wikipedia.org/wiki/Research_and_development'>R&D Leadership</a>, <a href='https://en.wikipedia.org/wiki/Product_development'>Product Development</a>. Suggested Search: 'Biomedical R&D Leadership Dhayakumar'";
                nextOptions = ["What's your current role?", "Tell me about your past experience", "What's your educational background?", "What are your skills?", "Any patents or publications?"];
                break;
            case "What's your current role?":
                response = `I'm currently ${personalInfo.currentRole.title} at ${personalInfo.currentRole.company} in ${personalInfo.currentRole.location} since ${personalInfo.currentRole.duration}. My main responsibilities include:\n\n${personalInfo.currentRole.responsibilities.join('\n')}`;
                references = "Keywords: <a href='https://en.wikipedia.org/wiki/Innovation'>Innovation</a>, <a href='https://en.wikipedia.org/wiki/Process_control'>Process Control</a>, <a href='https://en.wikipedia.org/wiki/Dentistry'>Dental R&D</a>. Suggested Search: 'Head of Innovations in Advanced manufacturing Research'";
                nextOptions = ["Tell me about your past experience", "What are your skills?", "Go back"];
                break;
            case "Tell me about your past experience":
                response = `Before my current role, I worked as:\n\n1. ${personalInfo.experience[0].title} at ${personalInfo.experience[0].company} (${personalInfo.experience[0].duration})\n2. ${personalInfo.experience[1].title} at ${personalInfo.experience[1].company} (${personalInfo.experience[1].duration})\n\nWould you like to know more about either of these roles?`;
                references = "Keywords: <a href='https://en.wikipedia.org/wiki/Bionics'>Bionic Engineer</a>, <a href='https://en.wikipedia.org/wiki/Chief_innovation_officer'>Chief Innovation Officer</a>, <a href='https://en.wikipedia.org/wiki/Microfluidics'>Microfluidics</a>. Suggested Search: 'Bionic Engineer Experience Dhayakumar'";
                nextOptions = ["Tell me about your role at Brinter Oy", "Tell me about your role at CHINFIN", "Go back"];
                break;
            case "Tell me about your role at Brinter Oy":
                response = `At Brinter Oy, I worked as ${personalInfo.experience[0].title} from ${personalInfo.experience[0].duration}. Key achievements include:\n\n${personalInfo.experience[0].achievements.join('\n')}`;
                references = "Keywords: <a href='https://en.wikipedia.org/wiki/Bioprinting'>Bioprinting</a>, <a href='https://en.wikipedia.org/wiki/Research_and_development'>Pharmaceutical R&D</a>, <a href='https://en.wikipedia.org/wiki/Patent'>Patents</a>. Suggested Search: 'Bioprinting R&D Brinter Oy Dhayakumar'";
                nextOptions = ["Tell me about your role at CHINFIN", "What are your skills?", "Go back"];
                break;
            case "Tell me about your role at CHINFIN":
                response = `At CHINFIN, I worked as ${personalInfo.experience[1].title} from ${personalInfo.experience[1].duration}. Key achievements include:\n\n${personalInfo.experience[1].achievements.join('\n')}`;
                references = "Keywords: <a href='https://en.wikipedia.org/wiki/Microfluidics'>Microfluidic Technologies</a>, <a href='https://en.wikipedia.org/wiki/Organ-on-a-chip'>Organ-on-Chip</a>, <a href='https://en.wikipedia.org/wiki/Collaboration'>Industrial Collaborations</a>. Suggested Search: 'Organ-on-Chip Development Dhayakumar'";
                nextOptions = ["Tell me about your role at Brinter Oy", "What are your skills?", "Go back"];
                break;
            case "What's your educational background?":
                response = `I have a strong educational background in biomedical engineering:\n\n${personalInfo.education.map(edu => `${edu.degree} from ${edu.institution} (${edu.duration})`).join('\n')}`;
                references = "Keywords: <a href='https://en.wikipedia.org/wiki/Pharmaceutical_sciences'>Pharmaceutical Science</a>, <a href='https://en.wikipedia.org/wiki/Nanotechnology'>Medical Nano Technology</a>, <a href='https://en.wikipedia.org/wiki/Bioengineering'>Bio-Engineering</a>. Suggested Search: 'Dhayakumar Education Background'";
                nextOptions = ["What are your skills?", "Any patents or publications?", "Go back"];
                break;
            case "What are your skills?":
                response = `My key skills include:\n\n${personalInfo.skills.join('\n')}`;
                references = "Keywords: <a href='https://en.wikipedia.org/wiki/Biomedical_techniques'>Biomedical Techniques</a>, <a href='https://en.wikipedia.org/wiki/Additive_manufacturing'>Additive Manufacturing</a>, <a href='https://en.wikipedia.org/wiki/Project_management'>Project Management</a>. Suggested Search: 'Key Skills of Dhayakumar'";
                nextOptions = ["Tell me about your past experience", "Any patents or publications?", "Go back"];
                break;
            case "Any patents or publications?":
                response = `Yes, I have 11 patents and my publications are available on ResearchGate:\n\n${personalInfo.patentsPublications.patents.join('\n')}\n\nPublications: ${personalInfo.patentsPublications.publications}`;
                references = "Keywords: <a href='https://en.wikipedia.org/wiki/Patent'>Patent</a>, <a href='https://en.wikipedia.org/wiki/ResearchGate'>ResearchGate</a>. Suggested Search: 'Dhayakumar Rajan Patents'";
                nextOptions = ["What are your skills?", "What languages do you speak?", "Go back"];
                break;
            case "What languages do you speak?":
                response = `I can communicate in:\n\n${personalInfo.languages.join('\n')}`;
                references = "Keywords: <a href='https://en.wikipedia.org/wiki/Multilingualism'>Multilingual Skills</a>, <a href='https://en.wikipedia.org/wiki/Language_proficiency'>Language Proficiency</a>. Suggested Search: 'Languages Spoken by Dhayakumar'";
                nextOptions = ["How can I contact you?", "Go back"];
                break;
            case "How can I contact you?":
                response = `You can reach me at:\nEmail: <a href='${personalInfo.contact.email}'>${personalInfo.contact.email}</a>\nLinkedIn: <a href='${personalInfo.contact.linkedin}'>LinkedIn Profile</a>`;
                references = "Keywords: <a href='https://en.wikipedia.org/wiki/Contact_information'>Contact Information</a>, <a href='https://en.wikipedia.org/wiki/LinkedIn'>LinkedIn Profile</a>. Suggested Search: 'Dhayakumar Contact'";
                nextOptions = ["Tell me about yourself", "Go back"];
                break;
            case "Go back":
                response = "What else would you like to know about me?";
                references = "Keywords: Overview. Suggested Search: 'Dhayakumar Overview'";
                nextOptions = ["Tell me about yourself", "What's your current role?", "Tell me about your past experience", "What's your educational background?", "What are your skills?", "Any patents or publications?", "What languages do you speak?", "How can I contact you?"];
                break;
            default:
                response = "I'm not sure how to respond to that. Could you please select one of the options?";
                references = "Keywords: Assistance, Guidance. Suggested Search: 'How to interact with chatbot'";
                nextOptions = ["Go back"];
        }

        setTimeout(() => {
            addMessage(response, 'bot');
            if (references) {
                addMessage(references, 'bot');
            }
            showOptions(nextOptions);
        }, 500);
    }, [personalInfo, addMessage, showOptions]);

    return (
        <div className="chat-container">
            <div className="chat-header">Chat with Dhayakumar Rajan Prakash</div>
            <div id="chat-window">
                {messages.map((message, index) => (
                    <div key={index} className={`message ${message.sender}-message`}>
                        <span dangerouslySetInnerHTML={{ __html: message.text }} />
                    </div>
                ))}
            </div>
            <div id="options-container">
                {options.map((option, index) => (
                    <button key={index} className="option-button" onClick={() => handleOptionClick(option)}>
                        {option}
                    </button>
                ))}
            </div>
            <Link to="/linkedin-banner" id="banner-generator-link">Create Your LinkedIn Banner</Link>
        </div>
    );
};

export default ChatInterface;