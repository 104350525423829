import React, { useState, useRef, useEffect, useCallback } from 'react';

const CircularBannerGenerator = () => {
  const [state, setState] = useState({
    image: null,
    bannerText: '#letsdoit 🫵',
    bannerColor: '#E4E3DD',
    textColor: '#030202',
    bannerWidth: 100,
    bannerLength: 130,
    bannerRotation: 115,
    imagePosition: { x: 0, y: 0 },
    imageScale: 1,
    isDragging: false,
    dragStart: { x: 0, y: 0 },
    isTextFlipped: true,
    textSpacing: 8,
    lastPinchDistance: null
  });

  const canvasRef = useRef(null);
  const fileInputRef = useRef(null);

  const updateState = useCallback((newState) => {
    setState(prev => ({ ...prev, ...newState }));
  }, []);

  const drawBanner = useCallback((ctx, size) => {
    const { bannerColor, textColor, bannerWidth, bannerLength, bannerText, isTextFlipped, textSpacing, bannerRotation } = state;
    const centerX = size / 2, centerY = size / 2, radius = size / 2;
    const bannerAngle = (bannerLength / 360) * Math.PI * 2;
    const startAngle = -Math.PI / 2 - bannerAngle / 2 + bannerRotation * Math.PI / 180;
    const endAngle = -Math.PI / 2 + bannerAngle / 2 + bannerRotation * Math.PI / 180;
    const fadeAngle = bannerAngle * 0.1;

    ctx.save();
    ctx.translate(centerX, centerY);
    ctx.rotate(bannerRotation * Math.PI / 180);
    ctx.translate(-centerX, -centerY);

    // Draw solid part
    ctx.beginPath();
    ctx.arc(centerX, centerY, radius, startAngle + fadeAngle, endAngle - fadeAngle);
    ctx.lineWidth = bannerWidth;
    ctx.strokeStyle = bannerColor;
    ctx.stroke();

    // Draw gradients
    const drawGradient = (start, end, reverse = false) => {
      const steps = 15;
      const step = (end - start) / steps;
      for (let i = 0; i < steps; i++) {
        const angle = start + i * step;
        const x = centerX + (radius - bannerWidth / 2) * Math.cos(angle);
        const y = centerY + (radius - bannerWidth / 2) * Math.sin(angle);
        const gradient = ctx.createRadialGradient(x, y, 2, x, y, 0);
        const alpha = reverse ? 1 - i / steps : i / steps;
        gradient.addColorStop(0, `${bannerColor}${Math.round(alpha * 255).toString(16).padStart(2, '0')}`);
        gradient.addColorStop(1, `${bannerColor}00`);
        ctx.beginPath();
        ctx.arc(centerX, centerY, radius, angle, angle + step);
        ctx.strokeStyle = gradient;
        ctx.stroke();
      }
    };

    drawGradient(startAngle, startAngle + fadeAngle);
    drawGradient(endAngle - fadeAngle, endAngle, true);

    // Draw text
    const textSize = Math.max(12, Math.min(35, bannerWidth * 0.6));
    ctx.font = `bold ${textSize}px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif`;
    ctx.fillStyle = textColor;
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';

    const textRadius = radius - bannerWidth / 4;
    const textStartAngle = isTextFlipped ? endAngle : startAngle;
    const textDirection = isTextFlipped ? -1 : 1;

    // Adding three leading spaces to banner text
    const adjustedBannerText = "   " + bannerText;
    
    [...adjustedBannerText].forEach((char, i) => {
      const angle = textStartAngle + textDirection * (i * textSpacing * (Math.PI / 180));
      ctx.save();
      ctx.translate(centerX + textRadius * Math.cos(angle), centerY + textRadius * Math.sin(angle));
      ctx.rotate(angle + (isTextFlipped ? -Math.PI / 2 : Math.PI / 2));
      ctx.fillText(char, 0, 0);
      ctx.restore();
    });

    ctx.restore();
  }, [state]);

  const drawImage = useCallback((ctx, size) => {
    const img = new Image();
    img.onload = () => {
      ctx.save();
      ctx.beginPath();
      ctx.arc(size / 2, size / 2, size / 2, 0, Math.PI * 2);
      ctx.clip();
      const scale = state.imageScale * Math.max(size / img.width, size / img.height);
      const x = (size / 2) - (img.width / 2) * scale + state.imagePosition.x;
      const y = (size / 2) - (img.height / 2) * scale + state.imagePosition.y;
      ctx.drawImage(img, x, y, img.width * scale, img.height * scale);
      ctx.restore();
      drawBanner(ctx, size);
    };
    img.src = state.image;
  }, [state.image, state.imageScale, state.imagePosition, drawBanner]);

  const generateImage = useCallback((canvas) => {
    const ctx = canvas.getContext('2d');
    const size = 400;
    canvas.width = size;
    canvas.height = size;

    ctx.fillStyle = '#FFFFFF';
    ctx.fillRect(0, 0, size, size);

    if (state.image) {
      drawImage(ctx, size);
    } else {
      drawBanner(ctx, size);
    }
  }, [state.image, drawImage, drawBanner]);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (canvas) generateImage(canvas);
  }, [generateImage]);


  const handleImageUpload = useCallback((file) => {
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => updateState({ image: e.target.result, imagePosition: { x: 0, y: 0 }, imageScale: 1 });
      reader.readAsDataURL(file);
    }
  }, [updateState]);
  
  const handleMouseDown = useCallback((e) => {
    if (state.image) {
      const rect = canvasRef.current.getBoundingClientRect();
      updateState({
        isDragging: true,
        dragStart: {
          x: e.clientX - rect.left - state.imagePosition.x,
          y: e.clientY - rect.top - state.imagePosition.y
        }
      });
    }
  }, [state.image, state.imagePosition, updateState]);

  const handleMouseMove = useCallback((e) => {
    if (state.isDragging) {
      const rect = canvasRef.current.getBoundingClientRect();
      updateState({
        imagePosition: {
          x: e.clientX - rect.left - state.dragStart.x,
          y: e.clientY - rect.top - state.dragStart.y
        }
      });
    }
  }, [state.isDragging, state.dragStart, updateState]);

  const handleTouchStart = useCallback((e) => {
    if (state.image) {
      const rect = canvasRef.current.getBoundingClientRect();
      const touch = e.touches[0];
      updateState({
        isDragging: true,
        dragStart: {
          x: touch.clientX - rect.left - state.imagePosition.x,
          y: touch.clientY - rect.top - state.imagePosition.y
        }
      });
    }
  }, [state.image, state.imagePosition, updateState]);

  const handleTouchMove = useCallback((e) => {
    e.preventDefault();
    if (e.touches.length === 1 && state.isDragging) {
      const rect = canvasRef.current.getBoundingClientRect();
      const touch = e.touches[0];
      updateState({
        imagePosition: {
          x: touch.clientX - rect.left - state.dragStart.x,
          y: touch.clientY - rect.top - state.dragStart.y
        }
      });
    } else if (e.touches.length === 2) {
      const touch1 = e.touches[0];
      const touch2 = e.touches[1];
      const currentDistance = Math.hypot(touch1.clientX - touch2.clientX, touch1.clientY - touch2.clientY);
      
      if (state.lastPinchDistance) {
        const delta = currentDistance - state.lastPinchDistance;
        const newScale = state.imageScale * (1 + delta * 0.01);
        updateState({ imageScale: Math.max(0.1, Math.min(5, newScale)) });
      }
      
      updateState({ lastPinchDistance: currentDistance });
    }
  }, [state.isDragging, state.dragStart, state.lastPinchDistance, state.imageScale, updateState]);

  const handleWheel = useCallback((e) => {
    e.preventDefault();
    if (state.image) {
      const newScale = state.imageScale * (e.deltaY > 0 ? 0.9 : 1.1);
      updateState({ imageScale: Math.max(0.1, Math.min(5, newScale)) });
    }
  }, [state.image, state.imageScale, updateState]);

  const handleTouchEnd = useCallback(() => {
    updateState({ isDragging: false, lastPinchDistance: null });
  }, [updateState]);

  const handleDownload = useCallback(() => {
    const canvas = canvasRef.current;
    if (canvas) {
      const link = document.createElement('a');
      link.download = 'linkedin-opentohelping-profile.png';
      link.href = canvas.toDataURL('image/png');
      link.click();
    }
  }, []);

  return (
    <div style={{ maxWidth: '800px', margin: '0 auto', padding: '20px', fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif'}}>
      <h1 style={{ textAlign: 'center', marginBottom: '20px' }}>LinkedIn Profile Picture Generator</h1>
      
      <div style={{ display: 'flex', flexWrap: 'wrap', gap: '20px', justifyContent: 'center' }}>
        <div style={{ flex: '1 1 300px', minWidth: '300px' }}>
          <div style={{ position: 'relative', width: '100%', paddingBottom: '100%', marginBottom: '20px' }}>
            <canvas 
              ref={canvasRef}
              onClick={() => !state.image && fileInputRef.current.click()}
              onMouseDown={handleMouseDown}
              onMouseMove={handleMouseMove}
              onMouseUp={() => updateState({ isDragging: false })}
              onMouseLeave={() => updateState({ isDragging: false })}
              onTouchStart={handleTouchStart}
              onTouchMove={handleTouchMove}
              onTouchEnd={handleTouchEnd}
              onWheel={handleWheel}
              onDragOver={(e) => e.preventDefault()}
              onDrop={(e) => {
                e.preventDefault();
                handleImageUpload(e.dataTransfer.files[0]);
              }}
              style={{ 
                position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', 
                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', borderRadius: '50%',
                cursor: state.image ? 'move' : 'pointer',
                touchAction: 'none'
              }} 
            />
            {!state.image && (
              <div style={{
                position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)',
                textAlign: 'center', pointerEvents: 'none'
              }}>
                <p>Tap to upload an image or drag & drop</p>
              </div>
            )}
          </div>
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: 'none' }}
            onChange={(e) => handleImageUpload(e.target.files[0])}
            accept="image/*"
          />
          <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
            <button onClick={handleDownload} style={{
              padding: '10px 20px', backgroundColor: '#0077B5', color: 'white',
              border: 'none', borderRadius: '4px', cursor: 'pointer',
              fontSize: '16px', fontWeight: 'bold'
            }}>Download</button>
            <button onClick={() => window.location.reload()} style={{
              padding: '10px 20px', backgroundColor: '#0077B5', color: 'white',
              border: 'none', borderRadius: '4px', cursor: 'pointer',
              fontSize: '16px', fontWeight: 'bold'
            }}>Clearall</button>
          </div>
        </div>

        <div style={{ flex: '1 1 300px', minWidth: '300px' }}>
          <div style={{ marginBottom: '15px' }}>
            <label htmlFor="bannerText" style={{ display: 'block', marginBottom: '5px' }}>
              Banner Text (Emojis are also supported 😉)
            </label>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <input
                id="bannerText"
                type="text"
                value={state.bannerText}
                onChange={(e) => updateState({ bannerText: e.target.value })}
                style={{ flex: 1, marginRight: '10px', padding: '5px', fontSize: '16px' }}
              />
              <label style={{ display: 'flex', alignItems: 'center' }}>
                <input
                  type="checkbox"
                  checked={state.isTextFlipped}
                  onChange={(e) => updateState({ isTextFlipped: e.target.checked })}
                  style={{ marginRight: '5px' }}
                />
                Flip
              </label>
            </div>
          </div>

            {['bannerColor', 'textColor'].map(prop => (
                <div key={prop} style={{ marginBottom: '15px' }}>
                    <label htmlFor={prop} style={{ display: 'block', marginBottom: '5px' }}>
                    {prop.charAt(0).toUpperCase() + prop.slice(1).replace(/([A-Z])/g, ' $1')}
                    </label>
                    <input
                    id={prop}
                    type="color"
                    value={state[prop]}
                    onChange={(e) => updateState({ [prop]: e.target.value })}
                    style={{ 
                        width: '100%', 
                        height: '40px', 
                        padding: '0', 
                        border: 'none',
                        borderRadius: '4px',
                        cursor: 'pointer'
                    }}
                    />
                </div>
            ))}

          {['textSpacing', 'bannerWidth', 'bannerLength', 'bannerRotation'].map(prop => (
            <div key={prop} style={{ marginBottom: '15px' }}>
              <label htmlFor={prop} style={{ display: 'block', marginBottom: '5px' }}>
                {prop.charAt(0).toUpperCase() + prop.slice(1).replace(/([A-Z])/g, ' $1')}: {state[prop]}
                {prop === 'bannerLength' || prop === 'bannerRotation' || prop === 'textSpacing' ? '°' : ''}
              </label>
              <input
                id={prop}
                type="range"
                min={prop === 'textSpacing' ? '1' : prop === 'bannerLength' ? '90' : '0'}
                max={prop === 'bannerWidth' ? '200' : prop === 'textSpacing' ? '50' : '360'}
                value={state[prop]}
                onChange={(e) => updateState({ [prop]: Number(e.target.value) })}
                style={{ width: '100%' }}
              />
            </div>
          ))}
        </div>
      
      </div>

      <div style={{ marginTop: '30px', padding: '20px', backgroundColor: '#f0f0f0', borderRadius: '8px' }}>
        <h2 style={{ marginBottom: '15px' }}>📢 #WhatI'mUpTo: Let the World Know!</h2>
        <p>Are you #LearningToCode? 💻 #LaunchingABusiness? 🛠️ #ExploringTheWorld? 🌍 Whatever it is, make your profile picture say it all!</p>
        <p>Introducing the Linked Profile Generator 🎉: Update your profile pic with a tag of what you're up to. One or two words that tell your story, spark conversations, and build connections.</p>
        <p>Upload, tag, save, and share! 💾 Let everyone know what you're up to and inspire your network!</p>
        <p>📍 Share it with your network and boost your visibility! Let everyone see what you're up to and inspire others to join the movement. 👊🌟 💡</p>
        <h3>Made with help of AI by Dhayakumar (DK) </h3>
      </div>

      <style>
        {`
          @media (max-width: 768px) {
            input[type="color"] {
              -webkit-appearance: none;
              border: none;
              width: 32px;
              height: 32px;
            }
            input[type="color"]::-webkit-color-swatch-wrapper {
              padding: 0;
            }
            input[type="color"]::-webkit-color-swatch {
              border: none;
              border-radius: 50%;
            }
          }
        `}
      </style>
    </div> 
  );
};

export default CircularBannerGenerator;